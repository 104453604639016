import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import BackIcon from "../../assets/SVGs/back-icon.svg";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import TextField from "../../global/components/textfield";

import * as Yup from "yup";
import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import { useDispatch } from "react-redux";
import fetch from "../../axios/manager";

export default function AddCoupon() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const formik = useFormik({
        initialValues: {
            code: "",
            discount: "",
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object().shape({
            code: Yup.string().required("Required"),
            discount: Yup.number().required("Required").max(100, "Max limit reached").min(1, "Min limit reached"),
        })
    });

    function onSubmit(values) {
        createCoupon();
    }

    async function createCoupon() {
        Loader.show();

        const [, error] = await fetch({
            route: "admins/auth/coupon",
            requestType: "post",
            body: {
                code: formik.values.code,
                discount: formik.values.discount,
            },
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        createCoupon();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        navigate(-1);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row items-center gap-4 mb-3">
            <PrimaryButton onClick={() => navigate(-1)} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={BackIcon} alt="back icon" />
                    <div className="font-medium">Back</div>
                </div>
            } />
            <div className="text-black font-bold text-lg dark:text-slate-200 my-3">Add Promo code</div>
        </div>
        <FormikProvider value={formik}>
            <form className="flex flex-col gap-2" onSubmit={formik.handleSubmit}>
                <div className="border-1 rounded-primary p-3 border-secondary dark:border-slate-700">
                    <div className="font-semibold dark:text-slate-200">Promo code Details</div>

                    <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-5">
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="coupon-code-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Promo code</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="code" id="coupon-code-field" placeholder="Code" type="text" size="w-[100%]" />
                                <ErrorMessage name="code" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="discount-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Discount (%)</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="discount" id="discount-field" placeholder="Discount" type="number" max={100} size="w-[100%]" />
                                <ErrorMessage name="discount" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-start">
                        <PrimaryButton onClick={formik.handleSubmit} child={
                            <div className="flex flex-row gap-3">
                                <div className="font-medium">Submit</div>
                            </div>
                        } />
                    </div>
                </div>
            </form>
        </FormikProvider>
    </div>;
}