import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import * as Alertdialog from "../../redux/slices/alertdialog";
import * as Loader from "../../global/functions/toggle-loader";

import BackIcon from "../../assets/SVGs/back-icon.svg";
import AddIcon from "../../assets/SVGs/add-icon.svg";
import DeleteButton from "../../global/components/delete-button";
import formatDate from "../../global/functions/format-date";
import { useEffect, useState } from "react";
import fetch from "../../axios/manager";
import { useDispatch } from "react-redux";
import PrimarySearchField from "../../global/components/primary-search-field";
import highlightString from "../../global/functions/substring-highlight";
import LoadMore from "../../global/components/load-more";

export default function CouponManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [coupons, setCoupons] = useState(null);
    const [searchCoupons, setSearchCoupons] = useState(null);
    const [couponHasMore, setCouponHasMore] = useState(true);
    const [searchCouponHasMore, setSearchCouponHasMore] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);

    useEffect(() => {
        fetchAllCoupons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchOffset(0);
        setSearchCouponHasMore(true);
        setSearchCoupons([]);

    }, [searchTerm]);

    useEffect(() => {
        if (offset === 0 || !couponHasMore) return;

        fetchAllCoupons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        if (searchOffset === 0 || !searchCouponHasMore) return;

        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOffset]);

    async function fetchAllCoupons() {
        Loader.show();

        const [couponsData, error] = await fetch({
            route: "admins/auth/coupons/all",
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllCoupons();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const mcouponsData = couponsData["res"];

        if (mcouponsData.length < 10) {

            setCouponHasMore(false);
        }

        setCoupons([
            ...(coupons ?? []),
            ...mcouponsData
        ]);

        Loader.hide();
    }
    async function deleteCoupon(uuid) {
        Loader.show();

        const [, error] = await fetch({
            route: `admins/auth/coupon/${uuid}`,
            requestType: "delete",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        deleteCoupon(uuid);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        let index = -1;
        for (let i = 0; i < coupons.length; i++) {
            if (coupons[i].uuid === uuid) {
                index = i;
            }
        }

        coupons.splice(index, 1);

        setCoupons([...coupons]);

        Loader.hide();
    }

    async function onSearch(term) {
        Loader.show();

        const [searchCouponData, error] = await fetch({
            route: `/admins/auth/coupons/search/${term}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: searchOffset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearch();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        const mSearchCouponData = searchCouponData["res"];

        if (mSearchCouponData.length < 10) {
            setSearchCouponHasMore(false);
        }

        setSearchCoupons([
            ...(searchCoupons ?? []),
            ...mSearchCouponData,
        ]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Promo codes</div>
            </div>

            <PrimaryButton onClick={() => navigate("/add-coupon")} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={AddIcon} alt="add-icon" />
                    <div className="font-medium">Add Promo code</div>
                </div>
            } />
        </div>

        <div className="mb-3 w-full">
            <PrimarySearchField onSearch={onSearch} onTermChange={setSearchTerm} />
        </div>

        {searchTerm.length === 0 ? (coupons == null || coupons.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No Promo code available
        </div>
            : <div className="flex flex-col gap-2">
                {coupons.map((item) =>
                    <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                        <div className="text-black dark:text-slate-300 font-medium text-lg">{`${item.code} - ${item.discount}%`}</div>
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>

                            <DeleteButton onClick={() => deleteCoupon(item.uuid)} />
                        </div>
                    </div>)}
                {couponHasMore && <LoadMore onClicked={() => setOffset(offset + 10)} />}
            </div>
            : (searchCoupons == null || searchCoupons.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                No result
            </div>
                : <div className="flex flex-col gap-2">
                    {searchCoupons.map((item) =>
                        <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                            {highlightString({ strTarget: `${item.code} - ${item.discount}`, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}
                            <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                                <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>

                                <DeleteButton onClick={() => deleteCoupon(item.uuid)} />
                            </div>
                        </div>)}
                    {searchCouponHasMore && <LoadMore onClicked={() => setSearchOffset(searchOffset + 10)} />}
                </div>}
    </div>;
}