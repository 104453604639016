import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import PrimaryButton from "../../global/components/button";
import formatDate from "../../global/functions/format-date";
import fetch from "../../axios/manager";
import LoadMore from "../../global/components/load-more";

export default function FeedbackManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [feedbacks, setFeedbacks] = useState(null);

    const [offset, setOffset] = useState(0);

    const [feedbackHasMore, setFeedbackHasMore] = useState(true);

    useEffect(() => {
        fetchAllFeedbacks();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (offset === 0 || !feedbackHasMore) return;

        fetchAllFeedbacks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    async function fetchAllFeedbacks() {
        Loader.show();

        const [feedbacksData, error] = await fetch({
            route: `admins/auth/feedbacks`,
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllFeedbacks();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const mfeedbacksData = feedbacksData["res"];

        if (mfeedbacksData.length < 10) {
            setFeedbackHasMore(false);
        }

        setFeedbacks([
            ...(feedbacks ?? []),
            ...mfeedbacksData,
        ]);

        Loader.hide();
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Feedbacks</div>
            </div>
        </div>

        {(feedbacks == null || feedbacks.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No Data available
        </div>
            : <div className="flex flex-col gap-2">
                {feedbacks.map((item) => <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                    <div className="text-black dark:text-slate-300 font-medium text-lg">{item.email}</div>
                    <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                        <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>
                        <PrimaryButton onClick={() => navigate(`/view-feedback/${item.uuid}`)} child={
                            <div className="flex flex-row items-center gap-3 px-2 h-3">
                                <div className="font-medium">View</div>
                            </div>
                        } />
                    </div>
                </div>)}
                {feedbackHasMore && <LoadMore onClicked={() => setOffset(offset + 10)} />}
            </div>}
    </div>;
}