import { ErrorMessage, FormikProvider, useFormik } from "formik";
import TextField from "../../global/components/textfield";
import PrimaryButton from "../../global/components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Yup from 'yup';
import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import fetch from "../../axios/manager";
import { useEffect, useMemo, useState } from "react";
import JoditEditor from "jodit-react";
import showToast from "../../global/functions/toggle-toast";

export default function EditPage() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const { uuid } = useParams();

    const [isDark, setIsDark] = useState(false);
    const [page, setPage] = useState(null);

    const options = ['bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: '',
            defaultActionOnPaste: 'insert_as_html',
            defaultLineHeight: 1.5,
            enter: 'div',
            buttons: options,
            buttonsMD: options,
            buttonsSM: options,
            buttonsXS: options,
            statusbar: false,
            sizeLG: 900,
            sizeMD: 700,
            sizeSM: 400,
            toolbarAdaptive: false,
            theme: isDark ? "dark" : "light",
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [isDark]);

    const formik = useFormik({
        initialValues: {
            title: "",
            html: "",
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Required"),
            html: Yup.string().required("Required"),
        })
    });

    useEffect(() => {
        fetchPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (page == null) return;

        formik.setFieldValue("title", page.title);
        formik.setFieldValue("html", page.html);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const element = document.documentElement;

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {

                    if (element.classList.contains("dark")) {
                        setIsDark(true);
                    }
                    else {
                        setIsDark(false);
                    }
                }
            });
        });

        observer.observe(element, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const element = document.documentElement;

        if (element.classList.contains("dark")) {
            setIsDark(true);
        }
        else {
            setIsDark(false);
        }

    }, []);

    function onSubmit(values) {
        onEditPage();
    }

    async function onEditPage() {
        Loader.show();

        const [, error] = await fetch({
            route: `admins/auth/pages/${uuid}`,
            requestType: "put",
            body: {
                title: formik.values.title,
                html: formik.values.html,
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onEditPage();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        showToast("Page updated successfully!", true);
    }

    async function fetchPage() {
        Loader.show();

        const [pageData, error] = await fetch({
            route: `admins/pages/${uuid}`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchPage();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        setPage(pageData["res"]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row items-center gap-4 mb-4">
            <PrimaryButton onClick={() => navigate(-1)} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={BackIcon} alt="back icon" />
                    <div className="font-medium">Back</div>
                </div>
            } />
            <div className="text-black font-bold text-lg dark:text-slate-200 my-3">Edit Page</div>
        </div>
        <FormikProvider value={formik}>
            <form className="flex flex-col gap-2" onSubmit={formik.handleSubmit}>
                <div className="border-1 rounded-primary p-3 border-secondary dark:border-slate-700">
                    <div className="font-semibold dark:text-slate-200">Page Details</div>

                    <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-5">
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="title-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Title</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="title" id="title-field" placeholder="Title" type="text" size="w-[100%]" />
                                <ErrorMessage name="title" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="dark:text-slate-200 px-2 font-medium w-[100%]">Content</label>
                        <div className="flex flex-col gap-1">
                            <JoditEditor
                                value={formik.values.html}
                                config={config}
                                onChange={(htmlString) => formik.setFieldValue("html", htmlString)}
                            />
                            <ErrorMessage name="html" className="text-red-500 text-xs font-medium" component="p" />
                        </div>
                    </div>
                    <div className="flex justify-start mt-3">
                        <PrimaryButton onClick={formik.handleSubmit} child={
                            <div className="flex flex-row gap-3">
                                <div className="font-medium">Submit</div>
                            </div>
                        } />
                    </div>
                </div>
            </form>
        </FormikProvider>
    </div>;
}