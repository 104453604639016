import { Link } from 'react-router-dom';
import * as Jwt from "../../cache/jwt";

export default function LogOutButton() {
    return <Link to="/login" className='flex items-center hover:cursor-pointer' onClick={async () => {
        Jwt.deleteToken();
    }}>
        <svg className='h-6 max-[400px]:h-5' width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_201_306)">
                <path className='dark:fill-slate-200' d="M15.6248 13.5419C15.0488 13.5419 14.5832 14.0086 14.5832 14.5834V18.7502C14.5832 19.3241 14.1165 19.7918 13.5415 19.7918H10.4165V4.16696C10.4165 3.27738 9.84983 2.48259 8.99782 2.18677L8.68941 2.08358H13.5415C14.1165 2.08358 14.5832 2.55126 14.5832 3.12536V6.25033C14.5832 6.8252 15.0488 7.29192 15.6248 7.29192C16.2009 7.29192 16.6664 6.8252 16.6664 6.25033V3.12536C16.6664 1.40247 15.2644 0.000396729 13.5415 0.000396729H2.34373C2.30405 0.000396729 2.27087 0.0181349 2.23234 0.0232847C2.18218 0.0190886 2.1343 0.000396729 2.08338 0.000396729C0.9344 0.000396729 0 0.934606 0 2.08358V20.8334C0 21.723 0.566667 22.5177 1.41867 22.8136L7.68749 24.9032C7.89997 24.9689 8.1113 25.0001 8.33331 25.0001C9.48229 25.0001 10.4165 24.0657 10.4165 22.9168V21.8752H13.5415C15.2644 21.8752 16.6664 20.4731 16.6664 18.7502V14.5834C16.6664 14.0086 16.2009 13.5419 15.6248 13.5419Z" fill="black" />
                <path className='dark:fill-slate-200' d="M24.6946 9.68039L20.5279 5.51383C20.2301 5.21591 19.7821 5.12626 19.3926 5.28762C19.0041 5.44917 18.7498 5.8293 18.7498 6.25025V9.37522H14.5833C14.0082 9.37522 13.5415 9.84175 13.5415 10.4168C13.5415 10.9919 14.0082 11.4584 14.5833 11.4584H18.7498V14.5834C18.7498 15.0043 19.0041 15.3844 19.3926 15.546C19.7821 15.7074 20.2301 15.6177 20.5279 15.32L24.6946 11.1532C25.1018 10.746 25.1018 10.0876 24.6946 9.68039Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_201_306">
                    <rect width="25" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>

        <div className='font-semibold px-2 text-dark dark:text-slate-300 text-xs'>Logout</div>
    </Link>;
};