import { Bounce, toast } from "react-toastify";

export default function showToast(text, success = false) {
    const isDark = document.documentElement.classList.contains("dark");

    if (success) {
        toast.success(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: isDark ? "dark" : "light",
            transition: Bounce,
        });
        return;
    }
    toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: isDark ? "dark" : "light",
        transition: Bounce,
    });
};