import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import PrimarySearchField from "../../global/components/primary-search-field";
import LoadMore from "../../global/components/load-more";
import highlightString from "../../global/functions/substring-highlight";
import formatDate from "../../global/functions/format-date";
import { useDispatch } from "react-redux";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import BackIcon from "../../assets/SVGs/back-icon.svg";
import fetch from "../../axios/manager";

export default function SubscribedUserlist() {
    const { subscriptionUUID } = useParams();
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);

    const [users, setUsers] = useState(null);
    const [searchUsers, setSearchUsers] = useState(null);

    const [userHasMore, setUserHasMore] = useState(true);
    const [searchUserHasMore, setSearchUserHasMore] = useState(true);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        fetchUserlist();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSearchOffset(0);
        setSearchUserHasMore(true);
        setSearchUsers([]);

    }, [searchTerm]);

    useEffect(() => {
        if (offset === 0 || !userHasMore) return;

        fetchUserlist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        if (searchOffset === 0 || !searchUserHasMore) return;

        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOffset]);

    async function fetchUserlist() {
        Loader.show();

        const [usersData, error] = await fetch({
            route: `/admins/auth/subscribedUsers/${subscriptionUUID}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchUserlist();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const musersData = usersData["res"].map(item => item.users);

        if (musersData.length < 10) {

            setUserHasMore(false);
        }

        setUsers([
            ...(users ?? []),
            ...musersData
        ]);

        Loader.hide();
    }

    async function onSearch(term) {
        Loader.show();

        const [usersData, error] = await fetch({
            route: `/admins/auth/subscribedUsers/${subscriptionUUID}/search/${term}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearch(term);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const musersData = usersData["res"].map(item => item.users);

        if (musersData.length < 10) {

            setSearchUserHasMore(false);
        }

        setSearchUsers([
            ...(searchUsers ?? []),
            ...musersData
        ]);

        Loader.hide();
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Subscribed users</div>
            </div>
        </div>

        <div className="mb-3 w-full">
            <PrimarySearchField onSearch={onSearch} onTermChange={setSearchTerm} />
        </div>

        {searchTerm.length === 0 ? (users == null || users.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No User available
        </div>
            : <div className="flex flex-col gap-2">
                {users.map((item) =>
                    <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                        <div className="text-black dark:text-slate-300 font-medium text-lg">{item.email}</div>
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>
                        </div>
                    </div>)}
                {userHasMore && <LoadMore onClicked={() => setOffset(offset + 10)} />}
            </div>
            : (searchUsers == null || searchUsers.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                No result
            </div>
                : <div className="flex flex-col gap-2">
                    {searchUsers.map((item) =>
                        <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                            {highlightString({ strTarget: item.email, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}
                            <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                                <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>
                            </div>
                        </div>)}
                    {searchUserHasMore && <LoadMore onClicked={() => setSearchOffset(searchOffset + 10)} />}
                </div>}
    </div>;
}