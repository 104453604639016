import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../assets/SVGs/back-icon.svg";
import CSVFileIcon from "../../assets/SVGs/csvfile-icon.svg";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import PrimaryButton from "../../global/components/button";
import formatDate, { formatDateTime } from "../../global/functions/format-date";
import fetch from "../../axios/manager";
import DropDown from "../../global/components/dropdown";
import LoadMore from "../../global/components/load-more";
import Datepicker from "react-tailwindcss-datepicker";
import Papa from 'papaparse';
import downloadFile from "../../global/functions/download-file";

export default function PaymentManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [records, setRecords] = useState({});

    const [dateRange, setDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(),
    });

    const [offset, setOffset] = useState({});

    const [recordsHasMore, setRecordsHasMore] = useState({});

    const [selectedType, setSelectedType] = useState("Homeowner");

    const types = { "Homeowner": "client", "Provider": "provider", "Enterprise": "enterprise", "All": "all" };

    function getCurrentKey() {
        if (dateRange.startDate == null || dateRange.endDate == null) {
            return `${selectedType}`;
        }

        return `${selectedType}-start-${dateRange.startDate.getTime()}-end-${dateRange.endDate.getTime()}`;
    }

    useEffect(() => {
        if ((offset[getCurrentKey()] ?? 0) === 0 || !(recordsHasMore[getCurrentKey()] ?? true)) return;

        fetchAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);


    useEffect(() => {
        if (dateRange.startDate == null || dateRange.endDate == null) return;
        if (records[getCurrentKey()] != null) return;

        fetchAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType, dateRange]);

    async function fetchAllUsers() {
        Loader.show();

        const [usersData, error] = await fetch({
            route: `/admins/auth/payment/records/${types[selectedType]}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: offset[getCurrentKey()] ?? 0,
                start: dateRange.startDate.getTime(),
                end: dateRange.endDate.getTime(),
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllUsers();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const musersData = usersData["res"];

        if (musersData.length < 10) {

            setRecordsHasMore({
                ...recordsHasMore,
                [getCurrentKey()]: false,
            });
        }

        const stateUsers = [
            ...(records[getCurrentKey()] ?? []),
            ...musersData,
        ];

        setRecords({
            ...records,
            [getCurrentKey()]: stateUsers,
        });

        Loader.hide();
    }

    async function toCSV() {
        let mrecordsOutput = [];

        if (recordsHasMore[getCurrentKey()] ?? true) {
            Loader.show();

            const [usersData, error] = await fetch({
                route: `/admins/auth/payment/records/${types[selectedType]}/all`,
                requestType: "get",
                params: {
                    start: dateRange.startDate.getTime(),
                    end: dateRange.endDate.getTime(),
                }
            });

            if (error != null) {
                dispatcher(Alertdialog.show({
                    type: "error",
                    title: "Error",
                    description: error,
                    positiveButtonText: "Retry",
                    onButtonClicked: (value) => {
                        if (value) {
                            fetchAllUsers();
                        }
                    }
                }));

                Loader.hide();
                return;
            }

            const mrecordsData = usersData["res"];

            setRecordsHasMore({
                ...recordsHasMore,
                [getCurrentKey()]: false,
            });

            setRecords({
                ...records,
                [getCurrentKey()]: mrecordsData,
            });

            mrecordsOutput = mrecordsData;

            Loader.hide();
        }
        else {
            mrecordsOutput = records[getCurrentKey()];
        }

        const mRecords = mrecordsOutput.map((item) => {
            return {
                Email: item.user.email,
                "Type of User": item.user.userType,
                "Paid on": formatDate(item.paidAt),
                "Plan": item.subscription.name,
                "Start Date": formatDate(item.start_date),
                "End Date": formatDate(item.end_date),
                "Transaction type": item.type === "subscription_cycle" ? "Renewal" : "First subscription",
            };
        });

        const csv = Papa.unparse(mRecords, {
            quotes: true,
        });

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        downloadFile(url, `records-${dateRange.startDate.toLocaleDateString()}-to-${dateRange.endDate.toLocaleDateString()}-type-${selectedType}.csv`);

        URL.revokeObjectURL(url);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-col mb-3">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center gap-4">
                    <PrimaryButton onClick={() => navigate(-1)} child={
                        <div className="flex flex-row gap-3">
                            <img className="primary-icon" src={BackIcon} alt="back-icon" />
                            <div className="font-medium">Back</div>
                        </div>
                    } />
                    <div className="text-black dark:text-slate-200 font-bold text-lg">All Records</div>
                </div>
                <div className="flex gap-2 h-10 w-[450px]">
                    <Datepicker
                        inputClassName="relative outline-none py-2.5 pl-4 pr-14 w-full dark:bg-slate-500 dark:text-white rounded-primary tracking-wide font-light text-sm placeholder-gray-400 bg-white"
                        primaryColor="indigo"
                        showShortcuts
                        configs={{
                            shortcuts: {
                                lastWeek: {
                                    text: "Last week",
                                    period: {
                                        start: new Date(new Date().setDate(new Date().getDate() - 7)),
                                        end: new Date(),
                                    },
                                },
                                lastQuater: {
                                    text: "Last 3 months",
                                    period: {
                                        start: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                                        end: new Date(),
                                    },
                                },
                                lastSemester: {
                                    text: "Last 6 months",
                                    period: {
                                        start: new Date(new Date().setMonth(new Date().getMonth() - 6)),
                                        end: new Date(),
                                    },
                                },
                                lastYear: {
                                    text: "Last year",
                                    period: {
                                        start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                                        end: new Date(),
                                    },
                                },
                            }
                        }}
                        separator="to"
                        maxDate={new Date()}
                        value={dateRange}
                        onChange={newDateRange => setDateRange(newDateRange)}
                    />
                    <DropDown className="h-10 shrink-0" label="type" items={Object.keys(types)} active={selectedType} onChange={setSelectedType} />
                </div>
            </div>
            {(records[getCurrentKey()] != null && records[getCurrentKey()].length !== 0) && <div className="flex flex-row justify-end h-11">
                <PrimaryButton onClick={() => toCSV()} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon h-5 aspect-square" src={CSVFileIcon} alt="csv file icon" />
                        <div className="font-medium">To CSV</div>
                    </div>
                } />
            </div>}
        </div>

        {(records[getCurrentKey()] == null || records[getCurrentKey()].length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No records available
        </div>
            : <div className="flex flex-col gap-2">
                {records[getCurrentKey()].map((item) => <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                    <div className="text-black dark:text-slate-300 font-normal text-md">
                        <span>{item.user.email}</span>
                        <span className="font-semibold"> {item.type === "subscription_cycle" ? "auto renewed" : "bought"}</span>
                        <span className="font-semibold"> {item.subscription.name}</span>
                    </div>
                    <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                        <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDateTime(item.createdAt)}</div>
                    </div>
                </div>)}
                {(recordsHasMore[getCurrentKey()] ?? true) && <LoadMore onClicked={() => setOffset({
                    ...offset,
                    [getCurrentKey()]: (offset[getCurrentKey()] ?? 0) + 10,
                })} />}
            </div>}
    </div>;
}