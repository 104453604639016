import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import PrimaryButton from "../../global/components/button";
import formatDate from "../../global/functions/format-date";
import fetch from "../../axios/manager";
import DropDown from "../../global/components/dropdown";
import LoadMore from "../../global/components/load-more";
import PrimarySearchField from "../../global/components/primary-search-field";
import highlightString from "../../global/functions/substring-highlight";

export default function UserManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [users, setUsers] = useState({});
    const [searchUsers, setSearchUsers] = useState({});

    const [offset, setOffset] = useState({});
    const [searchOffset, setSearchOffset] = useState({});

    const [userHasMore, setUserHasMore] = useState({});
    const [searchUserHasMore, setSearchUserHasMore] = useState({});

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedType, setSelectedType] = useState("Homeowner");

    const types = { "Homeowner": "client", "Provider": "provider", "Enterprise": "enterprise" };

    useEffect(() => {
        if ((offset[selectedType] ?? 0) === 0 || !(userHasMore[selectedType] ?? true)) return;

        fetchAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        setSearchOffset({
            [selectedType]: 0,
        });
        setSearchUserHasMore({
            [selectedType]: true,
        });
        setSearchUsers({
            [selectedType]: [],
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        if ((searchOffset[selectedType] ?? 0) === 0 || !(searchUserHasMore[selectedType] ?? true)) return;

        onSearchUsers(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOffset]);

    useEffect(() => {
        if (users[selectedType] != null) return;

        fetchAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    async function fetchAllUsers() {
        Loader.show();

        const [usersData, error] = await fetch({
            route: `admins/auth/users/${types[selectedType]}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: offset[selectedType] ?? 0,
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllUsers();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const musersData = usersData["res"];

        if (musersData.length < 10) {

            setUserHasMore({
                ...userHasMore,
                [selectedType]: false,
            });
        }

        const stateUsers = [
            ...(users[selectedType] ?? []),
            ...musersData,
        ];

        setUsers({
            ...users,
            [selectedType]: stateUsers,
        });

        Loader.hide();
    }

    async function onSearchUsers() {
        Loader.show();

        const [searchUsersData, error] = await fetch({
            route: `/admins/auth/users/${types[selectedType]}/search/${searchTerm}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: searchOffset[selectedType] ?? 0,
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearchUsers();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const msearchUsersData = searchUsersData["res"];

        if (msearchUsersData.length < 10) {

            setSearchUserHasMore({
                ...searchUserHasMore,
                [selectedType]: false,
            });
        }

        const stateSearchUsers = [
            ...(searchUsers[selectedType] ?? []),
            ...msearchUsersData,
        ];

        setSearchUsers({
            ...searchUsers,
            [selectedType]: stateSearchUsers,
        });

        Loader.hide();
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Users</div>
            </div>
            <DropDown className="h-10 shrink-0" label="type" items={Object.keys(types)} active={selectedType} onChange={setSelectedType} />
        </div>

        <div className="mb-3 w-full">
            <PrimarySearchField onSearch={onSearchUsers} onTermChange={setSearchTerm} />
        </div>

        {searchTerm.length === 0 ? (users[selectedType] == null || users[selectedType].length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No users available
        </div>
            : <div className="flex flex-col gap-2">
                {users[selectedType].map((item) => <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                    <div className="text-black dark:text-slate-300 font-medium text-lg">{item.email}</div>
                    <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                        <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>
                        <PrimaryButton onClick={() => navigate(`/user-details/${item.uuid}`)} child={
                            <div className="flex flex-row items-center gap-3 px-2 h-3">
                                <div className="font-medium">View</div>
                            </div>
                        } />
                    </div>
                </div>)}
                {(userHasMore[selectedType] ?? true) && <LoadMore onClicked={() => setOffset({
                    ...offset,
                    [selectedType]: (offset[selectedType] ?? 0) + 10,
                })} />}
            </div>
            : (searchUsers[selectedType] == null || searchUsers[selectedType].length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                No result
            </div>
                : <div className="flex flex-col gap-2">
                    {searchUsers[selectedType].map((item) => <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                        {highlightString({ strTarget: item.email, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>
                            <PrimaryButton onClick={() => navigate(`/user-details/${item.uuid}`)} child={
                                <div className="flex flex-row items-center gap-3 px-2 h-3">
                                    <div className="font-medium">View</div>
                                </div>
                            } />
                        </div>
                    </div>)}
                    {(searchUserHasMore[selectedType] ?? true) && <LoadMore onClicked={() => setSearchOffset({
                        ...searchOffset,
                        [selectedType]: (searchOffset[selectedType] ?? 0) + 10,
                    })} />}
                </div>}
    </div>;
}