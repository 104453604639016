import React from "react";
import { Link } from "react-router-dom";

export default function Card({ item, selected, onClick }) {
    const border = selected ? "border-selected dark:border-slate-500" : "border-disabled";
    const text = selected ? "text-selected dark:text-slate-200" : "text-black dark:text-slate-400";
    return <Link to={item["route"]} onClick={onClick} className={`bg-white dark:bg-slate-800 rounded-primary ${border} border-2 flex flex-col w-20 gap-2 text-center items-center py-5 basis-36 h-36 cursor-pointer justify-between`}>
        <div className="h-25 flex">
            {item["icon"]}
        </div>

        <div className={`${text} font-semibold text-sm`}>
            {item['label']}
        </div>
    </Link>;
};