import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import PrimaryButton from "../../global/components/button";
import fetch from "../../axios/manager";

export default function PagesManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [pages, setPages] = useState([]);

    useEffect(() => {
        fetchAllPages();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchAllPages() {
        Loader.show();

        const [pagesData, error] = await fetch({
            route: `admins/auth/pages`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllPages();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const mpagesData = pagesData["res"];

        setPages([...mpagesData]);

        Loader.hide();
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Pages</div>
            </div>
        </div>

        <div className="flex flex-col gap-2">
            {pages.map((item) => <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                <div className="text-black dark:text-slate-300 font-medium text-lg">{item.title}</div>
                <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                    <PrimaryButton onClick={() => navigate(`/edit-page/${item.uuid}`)} child={
                        <div className="flex flex-row items-center gap-3 px-2 h-3">
                            <div className="font-medium">View</div>
                        </div>
                    } />
                </div>
            </div>)}
        </div>
    </div>;
}