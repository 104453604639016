export default function toggelTheme() {
    const element = document.documentElement;
    if (element.classList.contains("dark")) {
        element.classList.remove("dark");

        localStorage.setItem("color-scheme", "light");
    }
    else {
        localStorage.setItem("color-scheme", "dark");
        element.classList.add("dark");
    }
};