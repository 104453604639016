export default function formatDate(date) {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: true
    });

    return formattedDate;
}

export function formatDateTime(date) {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        hour12: true,
        minute: '2-digit',
        second: '2-digit',
    });

    return formattedDate;
}
