import { useEffect, useState } from "react";
import PrimaryButton from "../global/components/button";
import TextField from "../global/components/textfield";
import { useTimer } from "react-timer-hook";

import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import * as Alertdialog from "../redux/slices/alertdialog";
import * as Loader from "../global/functions/toggle-loader";
import * as Cache from "../cache/cachemanager";
import fetch from "../axios/manager";
import { setToken } from "../cache/jwt";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function LoginScreen() {
    const [resendText, setResendText] = useState("Resend");
    const [otpAttempts, setOtpAttempts] = useState(1);
    const timer = useTimer({ autoStart: false, expiryTimestamp: new Date().getTime() + 60 });
    const navigate = useNavigate();

    const dispatcher = useDispatch();

    const [emailSubmitted, setEmailSubmitted] = useState(false);

    async function handleSubmit(values) {
        if (emailSubmitted) {
            const mOtp = values.otp.join('');
            Loader.show();

            const [loginData, loginError] = await fetch({
                route: `/admins/login/${formik.values.email}`,
                requestType: 'post',
                body: {
                    otp: mOtp,
                }
            });

            if (loginError != null) {
                Loader.hide();
                dispatcher(Alertdialog.show({
                    type: "error",
                    title: "Error",
                    description: loginError,
                    positiveButtonText: "Retry",
                    onButtonClicked: (value) => {
                        if (value) {
                            handleSubmit(values);
                        }
                    }
                }));

                return;
            }

            const token = loginData.token;

            await setToken({ jwt: token });

            Loader.hide();

            navigate("/");
        }
        else {
            resendOtp();
        }
    }

    useEffect(() => {
        initOtpAttmpts();
    }, []);

    async function initOtpAttmpts() {
        const attempts = await Cache.get("OtpAttempts");

        if (attempts != null) {
            setOtpAttempts(parseInt(attempts) + 1);

            Cache.set("OtpAttempts", parseInt(attempts) + 1);
        }
        else {
            Cache.set("otpAttempts", 1);
        }
    };

    async function onResendClick() {
        if (timer.isRunning) return;

        const blocked = await Cache.get("blocked");
        if (blocked != null && (Date.now() - blocked) / (1000 * 60 * 60) < 1) {
            setResendText("Blocked");
            return;
        }

        const attempts = await Cache.get("OtpAttempts");

        if (attempts != null) {
            setOtpAttempts(parseInt(attempts) + 1);

            Cache.set("OtpAttempts", parseInt(attempts) + 1);
        }
        else {
            setOtpAttempts((state) => state + 1);
            Cache.set("OtpAttempts", otpAttempts + 1);
        }

        if (otpAttempts >= 5) {
            setResendText("Blocked");

            await Cache.set("blocked", Date.now());
        }
        else {
            resendOtp();
        }
    };

    async function resendOtp() {
        Loader.show();

        const [, error] = await fetch({
            route: `admins/sendOtp/${formik.values.email}`,
            requestType: "post",
        });

        if (error != null) {
            Loader.hide();
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        resendOtp();
                    }
                }
            }));

            return;
        }

        Loader.hide();

        setEmailSubmitted(true);

        timer.restart(new Date().getTime() + 60000);
    }

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: handleSubmit,
    });

    return <div className="flex-grow flex flex-col justify-center items-center">
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className="max-w-[400px] w-full">
                <div className="flex flex-col gap-2 p-5 border-secondary dark:border-slate-700 rounded-primary border-1 w-[100%]">
                    <TextField required type="email" name="email" placeholder="Email" size={"w-[100%]"} autoComplete="off" />
                    <ErrorMessage name="email" className="text-red-500 text-xs font-medium" component="p" />
                    {emailSubmitted &&
                        <div className="flex flex-col">
                            <div className="flex flex-row gap-4 justify-between self-center mt-5 w-full">
                                <FieldArray name="otp" render={() =>
                                    [...Array(5)].map((_, index) =>
                                        <TextField id={`otp-[${index}]`} pattern="\d*" key={`otp[${index}]`} onChange={(e) => {
                                            const value = e.target.value;
                                            formik.setFieldValue(`otp[${index}]`, value);
                                            if (value === "") {
                                                if (index !== 0) {
                                                    document.getElementById(`otp-[${index - 1}]`).focus();
                                                }
                                                else { return; }
                                            }
                                            else {
                                                if (index === 4) return;
                                                document.getElementById(`otp-[${index + 1}]`).focus();
                                            }
                                        }}
                                            max={9} min={0} name={`otp[${index}]`} type="number" size="h-11 w-12"
                                            padding={false} extraCss="text-lg" innerCss="otp-field text-center" />
                                    )
                                }>
                                </FieldArray>
                            </div>
                            <h4 className="text-center text-md mt-3 dark:text-slate-200">Didn&lsquo;t Receive Code ?</h4>
                            <h4 onClick={() => onResendClick()} className="text-center text-md cursor-pointer font-medium underline dark:text-slate-200">{!timer.isRunning ? resendText : `Resend in ${timer.seconds} seconds`}</h4>
                        </div>}
                    <div className="flex justify-center mt-4 w-full">
                        <PrimaryButton onClick={formik.handleSubmit} type="submit" child={!emailSubmitted ? "Send OTP" : "Login"} />
                    </div>
                </div>
            </form>
        </FormikProvider>
    </div>;
}