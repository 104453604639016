import { ErrorMessage, FormikProvider, useFormik } from "formik";
import TextField from "../../global/components/textfield";
import PrimaryButton from "../../global/components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Yup from 'yup';
import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import { useEffect, useState } from "react";
import toFormData from "../../global/functions/to-formdata";
import fetch from "../../axios/manager";
import { domain } from "../../axios/adapter";

export default function EditCategory() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [url, setUrl] = useState(null);

    const { mainListingUUID } = useParams();
    const [mainListing, setMainlisting] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            file: null,
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
        })
    });

    function onSubmit(values) {
        onEditListing();
    }

    useEffect(() => {
        fetchMainListing();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mainListing == null) return;

        formik.setFieldValue("name", mainListing.name);
        setUrl(`${domain}/static/${mainListing.image}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainListing]);

    async function onEditListing() {
        Loader.show();

        const obj = {
            name: formik.values.name,
        };

        if (formik.values.file != null) {
            obj.image = formik.values.file;
        }

        const [, error] = await fetch({
            route: `/listings/auth/mainListing/${mainListingUUID}`,
            requestType: "put",
            body: toFormData(obj)
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onEditListing();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        navigate(-1);
    }

    async function fetchMainListing() {
        Loader.show();

        const [mainListingData, error] = await fetch({
            route: `/listings/mainListing/${mainListingUUID}`,
            requestType: "get",
        });


        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchMainListing();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        setMainlisting(mainListingData["res"]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row items-center gap-4">
            <PrimaryButton onClick={() => navigate(-1)} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={BackIcon} alt="back icon" />
                    <div className="font-medium">Back</div>
                </div>
            } />
            <div className="text-black font-bold text-lg dark:text-slate-200 my-3">Add Category</div>
        </div>
        <FormikProvider value={formik}>
            <form className="flex flex-col gap-2" onSubmit={formik.handleSubmit}>
                <div className="border-1 rounded-primary p-3 border-secondary dark:border-slate-700">
                    <div className="font-semibold dark:text-slate-200">Category Details</div>

                    <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-5">
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="name-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Category name</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="name" id="name-field" placeholder="Name" type="text" size="w-[100%]" />
                                <ErrorMessage name="name" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                    </div>
                    <div className="border-1 rounded-primary p-4 border-secondary dark:border-slate-700 flex flex-col gap-2 mb-3">
                        <div className="font-semibold ml-1 dark:text-slate-200">Upload image</div>

                        <div className="flex flex-row w-[100%] items-center gap-5">
                            <input name="file" placeholder="Select Image" type="file" id="filePicker" multiple={false} accept="image/*"
                                onChange={(e) => {
                                    const file = e.currentTarget.files[0];
                                    if (file == null) {
                                        return;
                                    }
                                    formik.setFieldValue("file", file);

                                    const murl = URL.createObjectURL(file);

                                    setUrl(murl);
                                }} hidden />
                            <label className="bg-white rounded-primary p-3 w-1/6 max-sm:w-1/2 max-md:w-1/3 cursor-pointer dark:bg-slate-800 dark:text-slate-200" htmlFor="filePicker">Select File</label>
                            {url != null && <img alt="listing" className="h-10 aspect-video object-cover rounded-md" src={url} />}
                        </div>

                        <ErrorMessage name="file" className="text-red-500 text-xs font-medium" component="p" />
                    </div>

                    <div className="flex justify-start">
                        <PrimaryButton onClick={formik.handleSubmit} child={
                            <div className="flex flex-row gap-3">
                                <div className="font-medium">Submit</div>
                            </div>
                        } />
                    </div>
                </div>
            </form>
        </FormikProvider>
    </div>;
}