import MainContent from "../screens/main-content-wrapper";
import Card from "../components/dashboard-card";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import CouponIcon from "../../assets/SVGs/coupon.svg";
import MainListingIcon from "../../assets/SVGs/main-listing.svg";
import UsermanagementIcon from "../../assets/SVGs/user-management.svg";
import AdminMananement from "../../assets/SVGs/admin-management.svg";
import SubscriptionIcon from "../../assets/SVGs/subscription-icon.svg";
import EmailManagement from "../../assets/SVGs/email-management.svg";
import NotificationManagement from "../../assets/SVGs/notification-management.svg";
import PaymentManagement from "../../assets/SVGs/payment-management.svg";
import PagesManagement from "../../assets/SVGs/pages-management.svg";
import UserFeedbacks from "../../assets/SVGs/feedback.svg";

const items = [
    {
        "label": "User Management",
        "icon": <img src={UsermanagementIcon} alt="user management" className="h-14" />,
        "route": "user-management"
    },
    {
        "label": "Listings Management",
        "icon": <img src={MainListingIcon} alt="main listings" className="h-14 w-14" />,
        "route": "listing-management"
    },
    {
        "label": "Promo codes Management",
        "icon": <img src={CouponIcon} alt="promo codes management" className="h-14 w-14" />,
        "route": "promocodes-management"
    },
    {
        "label": "Admin Management",
        "icon": <img src={AdminMananement} alt="admin management" className="h-14 w-14" />,
        "route": "admin-management"
    },
    {
        "label": "Subscription Management",
        "icon": <img src={SubscriptionIcon} alt="subscription management" className="h-14 w-14" />,
        "route": "subscription-management"
    },
    {
        "label": "Payment Management",
        "icon": <img src={PaymentManagement} alt="payment management" className="h-14 w-14" />,
        "route": "payment-management"
    },
    {
        "label": "Send Emails",
        "icon": <img src={EmailManagement} alt="email management" className="h-14 w-14" />,
        "route": "email-management"
    },
    {
        "label": "Send Notifications",
        "icon": <img src={NotificationManagement} alt="notification management" className="h-14 w-14" />,
        "route": "notification-management"
    },
    {
        "label": "Pages Management",
        "icon": <img src={PagesManagement} alt="pages management" className="h-14 w-14" />,
        "route": "pages-management"
    },
    {
        "label": "User feedbacks",
        "icon": <img src={UserFeedbacks} alt="users Feedback" className="h-14 w-14" />,
        "route": "feedback-management"
    },
];


export default function Dashboard() {
    const [selected, setSelected] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            setSelected("");
        }
        else {
            initSelected();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    function initSelected() {
        const url = location.pathname;

        for (let i = 0; i < items.length; i++) {
            if (url.includes(items[i].route)) {
                setSelected(items[i].label);
            }
        }
    }

    return <div className="bg-primary dark:bg-slate-900">
        <div className="flex flex-wrap flex-grow basis-72 flex-row justify-center pt-6 gap-x-4 gap-y-4 mx-3-min select-none">
            {items.map(item => <Card key={item["label"]} item={item}
                selected={selected === item["label"]} onClick={() => setSelected(item["label"])} />)}
        </div>

        <div className="flex justify-center mt-7">
            <MainContent />
        </div>
    </div>;
};