import { Outlet } from "react-router-dom";
import * as Jwt from "../../cache/jwt";
import { useEffect, useState } from "react";
import LoginScreen from "../login";

export default function AuthHandler() {
    const [token, setToken] = useState("");

    useEffect(() => {
        init();
    }, []);

    async function init() {
        const mtoken = await Jwt.getToken();

        setToken(mtoken);
    }

    if (token == null) {
        window.location.replace("/login");

        return <LoginScreen />;
    }

    return <Outlet />;
}