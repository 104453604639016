import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import BackIcon from "../../assets/SVGs/back-icon.svg";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import TextField from "../../global/components/textfield";

import * as Yup from "yup";
import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import CloseIcon from "../../assets/SVGs/close-icon.svg";
import JoditEditor from "jodit-react";
import { useEffect, useMemo, useState } from "react";
import DropDown from "../../global/components/dropdown";
import PrimarySearchField from "../../global/components/primary-search-field";
import { Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import highlightString from "../../global/functions/substring-highlight";
import LoadMore from "../../global/components/load-more";
import AddIcon from "../../assets/SVGs/add-icon.svg";
import fetch from "../../axios/manager";
import showToast from "../../global/functions/toggle-toast";

export default function EmailManagement() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const options = ['bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];

    const types = { "Homeowner": "client", "Provider": "provider", "Enterprise": "enterprise", "All": "all", "Custom": "custom" };

    const [isDark, setIsDark] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [searchUsers, setSearchUsers] = useState(null);
    const [searchOffset, setSearchOffset] = useState(0);
    const [searchUserHasMore, setSearchUserHasMore] = useState(true);

    const [showSearch, setShowSearch] = useState(false);

    let boundArrayHelpers;

    const config = useMemo(
        () => ({
            readonly: false,
            placeholder: '',
            defaultActionOnPaste: 'insert_as_html',
            defaultLineHeight: 1.5,
            enter: 'div',
            buttons: options,
            buttonsMD: options,
            buttonsSM: options,
            buttonsXS: options,
            statusbar: false,
            sizeLG: 900,
            sizeMD: 700,
            sizeSM: 400,
            toolbarAdaptive: false,
            theme: isDark ? "dark" : "light",
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [isDark]);

    useEffect(() => {
        const element = document.documentElement;

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {

                    if (element.classList.contains("dark")) {
                        setIsDark(true);
                    }
                    else {
                        setIsDark(false);
                    }
                }
            });
        });

        observer.observe(element, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const element = document.documentElement;

        if (element.classList.contains("dark")) {
            setIsDark(true);
        }
        else {
            setIsDark(false);
        }

    }, []);

    useEffect(() => {
        if (searchTerm === "") {
            setShowSearch(false);
        }

        setSearchOffset(0);
        setSearchUserHasMore(true);
        setSearchUsers([]);

    }, [searchTerm]);

    useEffect(() => {
        if (searchOffset === 0 || !(searchUserHasMore ?? true)) return;

        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOffset]);

    const formik = useFormik({
        initialValues: {
            subject: "",
            userType: "Homeowner",
            html: "",
            emailList: [],
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object().shape({
            subject: Yup.string().required("Required"),
            html: Yup.string().required("Required"),
            userType: Yup.string().required("Required"),
            emailList: Yup.array().test('email-validation', "At least one email is required", function (value) {
                const { userType } = this.parent;
                if (userType === "Custom") {
                    return value && value.length > 0 && value.every(email => Yup.string().email().isValidSync(email));
                }
                return true;
            }),
        })
    });

    function onSubmit(values) {
        sendMail();
    }

    async function sendMail() {
        Loader.show();

        const [, error] = await fetch({
            route: "/admins/auth/mails/send",
            requestType: "post",
            body: {
                type: types[formik.values.userType],
                emailList: formik.values.emailList,
                subject: formik.values.subject,
                html: formik.values.html,
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        sendMail();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        showToast("Email sent successfully!", true);
    }

    async function onSearch() {
        setShowSearch(true);

        Loader.show();

        const [searchUsersData, error] = await fetch({
            route: `/admins/auth/users/all/search/${searchTerm}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: searchOffset,
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearch();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const msearchUsersData = searchUsersData["res"];

        if (msearchUsersData.length < 10) {
            setSearchUserHasMore(false);
        }

        setSearchUsers([
            ...(searchUsers ?? []),
            ...msearchUsersData,
        ]);

        Loader.hide();
    }

    const bindArrayHelpers = (arrayHelpers) => {
        boundArrayHelpers = arrayHelpers;
    };

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row items-center gap-4 mb-3">
            <PrimaryButton onClick={() => navigate(-1)} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={BackIcon} alt="back icon" />
                    <div className="font-medium">Back</div>
                </div>
            } />
            <div className="text-black font-bold text-lg dark:text-slate-200 my-3">Send Email</div>
        </div>
        <FormikProvider value={formik}>
            <form className="flex flex-col gap-2" onSubmit={formik.handleSubmit}>
                <div className="font-semibold dark:text-slate-200">Email Details</div>
                <div className="border-1 rounded-primary p-3 border-secondary dark:border-slate-700 flex flex-col gap-3">

                    <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-2">
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="subject-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Subject</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="subject" id="subject-field" placeholder="Subject" type="text" size="w-[100%]" />
                                <ErrorMessage name="subject" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                            <label className="dark:text-slate-200 px-2 font-medium w-[100%]">Type</label>
                            <div className="flex flex-col gap-1">
                                <DropDown className="h-10 shrink-0" label="type"
                                    items={Object.keys(types)} active={formik.values.userType} onChange={(value) => formik.setFieldValue("userType", value)} />
                                <ErrorMessage name="userType" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                    </div>
                    {types[formik.values.userType] === "custom" && <div className="flex flex-col w-full gap-2">
                        <label htmlFor="emaillist-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Email List</label>
                        <div className="relative">
                            <PrimarySearchField onSearch={onSearch} onTermChange={setSearchTerm} />
                            <Transition show={showSearch} className="absolute right-0 z-10 mt-2 top-10 min-h-[300px] h-full w-full origin-top-right rounded-md bg-white dark:bg-slate-700 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                                <div className="max-h-[200px]">
                                    <div className="flex flex-col gap-2 h-full overflow-y-auto">
                                        {(searchUsers == null || searchUsers.length === 0) ? <div className="flex-grow h-full flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                                            No result
                                        </div>
                                            : <div className="flex flex-col gap-2 py-2">
                                                {searchUsers.map((item) => <div key={item.uuid} className="flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%] h-11">
                                                    {highlightString({ strTarget: item.email, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}
                                                    <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                                                        <PrimaryButton onClick={() => {
                                                            boundArrayHelpers.push(item.email);
                                                            setShowSearch(false);
                                                        }} child={
                                                            <div className="flex flex-row items-center gap-3 px-2 h-3">
                                                                <img className="primary-icon" src={AddIcon} alt="add icon" />
                                                                <div className="font-medium">Add</div>
                                                            </div>
                                                        } />
                                                    </div>
                                                </div>)}
                                                {(searchUserHasMore ?? true) &&
                                                    <LoadMore style={{ backgroundColor: "transparent" }} onClicked={() => setSearchOffset(searchOffset + 10)} />}
                                            </div>}
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    </div>}
                    <ErrorMessage name="emailList" className="text-red-500 text-xs font-medium" component="p" />
                    {types[formik.values.userType] === "custom" && <FieldArray name="emailList" render={arrayHelpers => {
                        bindArrayHelpers(arrayHelpers);

                        return <div className="flex gap-2 flex-wrap">
                            {formik.values.emailList.map((item, index) => {
                                return <div key={item} className="h-9 w-max rounded-full px-4 bg-slate-200 dark:bg-slate-600 flex gap-2 items-center">
                                    <div className="text-black dark:text-slate-200 text-sm">{item}</div>
                                    <img onClick={() => boundArrayHelpers.remove(index)} className="primary-icon cursor-pointer w-5 aspect-square object-contain" src={CloseIcon} alt="close icon" />
                                </div>;
                            })}
                        </div>;
                    }} />}
                    <div className="flex flex-col gap-2">
                        <label className="dark:text-slate-200 px-2 font-medium w-[100%]">Content</label>
                        <div className="flex flex-col gap-1">
                            <JoditEditor
                                value={formik.values.html}
                                config={config}
                                onChange={(htmlString) => formik.setFieldValue("html", htmlString)}
                            />
                            <ErrorMessage name="html" className="text-red-500 text-xs font-medium" component="p" />
                        </div>
                    </div>
                    <div className="flex justify-start">
                        <PrimaryButton onClick={formik.handleSubmit} child={
                            <div className="font-medium">Submit</div>
                        } />
                    </div>
                </div>
            </form>
        </FormikProvider>
    </div>;
}