import React from "react";
import ReactDOM from "react-dom/client";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopBar from "./global/components/header";
import Loader from "./global/components/loader";
import ToploadingBar from "./global/components/toploading-bar";
import PageNotFound from "./global/screens/not-found";
import Alertdialog from "./global/components/dialog";
import Dashboard from "./global/screens/dashboard";
import UserManagementDashboard from "./userside/user-management/dashboard";
import AdminManagementDashboard from "./userside/admin-management/dashboard";
import CouponManagementDashboard from "./userside/coupon-management/dashboard";
import AddCoupon from "./userside/coupon-management/add-coupon";
import ListingManagementDashboard from "./userside/listing-management/dashboard";
import SubListingsManagementDashboard from "./userside/listing-management/subcategory-management";
import LoginScreen from "./userside/login";
import { ToastContainer } from "react-toastify";
import { tailChase } from "ldrs";
import 'react-toastify/dist/ReactToastify.css';

import "./assets/styles/main.css";
import AuthHandler from "./userside/auth/auth-handler";
import AddAdmin from "./userside/admin-management/add-admin";
import EditAdmin from "./userside/admin-management/edit-admin";
import AddCategory from "./userside/listing-management/add-category";
import AddSubcategory from "./userside/listing-management/add-subcategory";
import EditSubcategory from "./userside/listing-management/edit-subcategory";
import EditCategory from "./userside/listing-management/edit-category";
import SubscriptionManagementDashboard from "./userside/subscription-management/dashboard";
import EditSubscription from "./userside/subscription-management/edit-subscription";
import SubscribedUserlist from "./userside/subscription-management/subscribed-userlist";
import EmailManagement from "./userside/email-management/dashboard";
import NotificationManagement from "./userside/notification-management/dashboard";
import UserDetails from "./userside/user-management/user-details";
import PaymentManagementDashboard from "./userside/payment-management/dashboard";
import PagesManagementDashboard from "./userside/pages-management/dashboard";
import EditPage from "./userside/pages-management/edit-page";
import FeedbackManagementDashboard from "./userside/feedback-management/dashboard";
import ViewFeedbackScreen from "./userside/feedback-management/view-feedback";

const root = ReactDOM.createRoot(document.getElementById('root'));
tailChase.register();

root.render(
  <Provider store={store}>
    <ToastContainer
      progressClassName="rounded-primary"
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      stacked
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
    />
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={
          <div className="flex flex-col min-h-[100dvh] h-full bg-primary dark:bg-slate-900">
            <Loader />
            <ToploadingBar />
            <Alertdialog />
            <TopBar />

            <Routes>
              <Route element={<AuthHandler />} >
                <Route path="/" element={<Dashboard />} >
                  <Route path="user-management" element={<UserManagementDashboard />}></Route>
                  <Route path="user-details/:uuid" element={<UserDetails />}></Route>
                  <Route path="promocodes-management" element={<CouponManagementDashboard />}></Route>
                  <Route path="add-coupon" element={<AddCoupon />}></Route>
                  <Route path="listing-management" element={<ListingManagementDashboard />}></Route>
                  <Route path="add-category" element={<AddCategory />}></Route>
                  <Route path="edit-category/:mainListingUUID" element={<EditCategory />}></Route>
                  <Route path="subcategory-management/:mainListingUUID" element={<SubListingsManagementDashboard />}></Route>
                  <Route path="subcategory-management/:mainListingUUID/add-subcategory" element={<AddSubcategory />}></Route>
                  <Route path="subcategory-management/:mainListingUUID/edit-subcategory/:subcategoryUUID" element={<EditSubcategory />}></Route>
                  <Route path="admin-management" element={<AdminManagementDashboard />}></Route>
                  <Route path="add-admin" element={<AddAdmin />}></Route>
                  <Route path="edit-admin/:uuid" element={<EditAdmin />}></Route>
                  <Route path="subscription-management" element={<SubscriptionManagementDashboard />}></Route>
                  <Route path="edit-subscription/:subscriptionUUID" element={<EditSubscription />}></Route>
                  <Route path="subscribed-users/:subscriptionUUID" element={<SubscribedUserlist />}></Route>
                  <Route path="payment-management" element={<PaymentManagementDashboard />}></Route>
                  <Route path="email-management" element={<EmailManagement />}></Route>
                  <Route path="notification-management" element={<NotificationManagement />}></Route>
                  <Route path="pages-management" element={<PagesManagementDashboard />}></Route>
                  <Route path="edit-page/:uuid" element={<EditPage />}></Route>
                  <Route path="feedback-management" element={<FeedbackManagementDashboard />}></Route>
                  <Route path="view-feedback/:feedbackUUID" element={<ViewFeedbackScreen />}></Route>
                </Route>
              </Route>

              <Route path="/login" element={<LoginScreen />}></Route>
              <Route
                path="*"
                element={<PageNotFound />}
              />
            </Routes>
          </div>
        }>
        </Route>
      </Routes>
    </BrowserRouter >
  </Provider >
);
