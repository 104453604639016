import { useEffect, useState } from "react";
import SearchIcon from "../../assets/SVGs/search-icon.svg";

export default function PrimarySearchField({ onSearch, onTermChange }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTerm(searchTerm);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedTerm) {
            onSearch(debouncedTerm);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedTerm]);

    return <div className="w-full flex gap-3 px-3 py-2 rounded-primary h-11 items-center dark:bg-slate-800 bg-white">
        <img src={SearchIcon} className="primary-icon h-4 aspect-square object-contain" alt="search" />
        <input type="text" placeholder="Search..." className="dark:text-slate-200 bg-transparent outline-none flex-grow"
            value={searchTerm} onChange={(e) => {
                setSearchTerm(e.target.value);
                onTermChange(e.target.value);
            }} />
    </div>;
}