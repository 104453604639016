import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import * as Alertdialog from "../../redux/slices/alertdialog";
import * as Loader from "../../global/functions/toggle-loader";

import { domain } from "../../axios/adapter";
import BackIcon from "../../assets/SVGs/back-icon.svg";
import AddIcon from "../../assets/SVGs/add-icon.svg";
import DeleteButton from "../../global/components/delete-button";
import { useEffect, useState } from "react";
import fetch from "../../axios/manager";
import { useDispatch } from "react-redux";
import PrimarySearchField from "../../global/components/primary-search-field";
import highlightString from "../../global/functions/substring-highlight";

export default function ListingsManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [mainlistings, setMainListings] = useState(null);
    const [searchMainlistings, setSearchMainlistings] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [offset,] = useState(0);

    useEffect(() => {
        fetchAllMainlistings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchAllMainlistings() {
        Loader.show();

        const [couponsData, error] = await fetch({
            route: "/listings/listing/all",
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllMainlistings();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        setMainListings([...couponsData["res"]]);

        Loader.hide();
    }
    async function deleteCategory(uuid) {
        Loader.show();

        const [, error] = await fetch({
            route: `/listings/auth/mainListing/${uuid}`,
            requestType: "delete",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        deleteCategory(uuid);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        let index = -1;
        for (let i = 0; i < mainlistings.length; i++) {
            if (mainlistings[i].uuid === uuid) {
                index = i;
            }
        }

        mainlistings.splice(index, 1);

        setMainListings([...mainlistings]);

        Loader.hide();
    }

    async function onSearch(term) {
        Loader.show();

        const [searchMainlistingData, error] = await fetch({
            route: `/listings/mainlisting/search/${term}`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearch(term);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        setSearchMainlistings(searchMainlistingData["res"]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="add-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Categories</div>
            </div>

            <PrimaryButton onClick={() => navigate("/add-category")} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={AddIcon} alt="add-icon" />
                    <div className="font-medium">Add Category</div>
                </div>
            } />
        </div>

        <div className="mb-3 w-full">
            <PrimarySearchField onSearch={onSearch} onTermChange={setSearchTerm} />
        </div>

        {searchTerm.length === 0 ? (mainlistings == null || mainlistings.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No Categories available
        </div>
            : <div className="flex flex-col gap-2">
                {mainlistings.map((item) =>
                    <div key={item.uuid} className="bg-white h-11 dark:bg-slate-800 flex flex-row justify-between p-2 rounded-primary items-center w-[100%]">
                        <div className="flex gap-3 items-center h-full">
                            <img height="30" width="50" loading="lazy" className="h-full aspect-video object-cover rounded-md" src={`${domain}/static/${item.image}`} alt={item.name} />
                            <div className="text-black dark:text-slate-300 font-medium text-lg">{`${item.name}`}</div>
                        </div>
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">
                                {`Subcategories - ${item.listings.length}`}
                            </div>

                            <PrimaryButton onClick={() => navigate(`/subcategory-management/${item.uuid}`)} child={
                                <div className="flex flex-row items-center gap-3 px-2 h-3">
                                    <div className="font-medium">View</div>
                                </div>
                            } />

                            <DeleteButton onClick={() => deleteCategory(item.uuid)} />
                        </div>
                    </div>)}
            </div>
            : (searchMainlistings == null || searchMainlistings.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                No result
            </div>
                : <div className="flex flex-col gap-2">
                    {searchMainlistings.map((item) =>
                        <div key={item.uuid} className="bg-white h-11 dark:bg-slate-800 flex flex-row justify-between p-2 rounded-primary items-center w-[100%]">
                            <div className="flex gap-3 items-center h-full">
                                <img height="30" width="50" className="h-full aspect-video object-cover rounded-md" src={`${domain}/static/${item.image}`} alt={item.name} />
                                {highlightString({ strTarget: item.name, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}
                            </div>
                            <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                                <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">
                                    {`Subcategories - ${item.listings.length}`}
                                </div>

                                <PrimaryButton onClick={() => navigate(`/subcategory-management/${item.uuid}`)} child={
                                    <div className="flex flex-row items-center gap-3 px-2 h-3">
                                        <div className="font-medium">View</div>
                                    </div>
                                } />

                                <DeleteButton onClick={() => deleteCategory(item.uuid)} />
                            </div>
                        </div>)}
                </div>}
    </div>;
}