import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import PrimaryButton from "../../global/components/button";
import formatDate from "../../global/functions/format-date";
import fetch from "../../axios/manager";
import DropDown from "../../global/components/dropdown";
import { domain } from "../../axios/adapter";

export default function SubscriptionManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [subscriptions, setSubscriptions] = useState(null);

    const [selectedType, setSelectedType] = useState("Homeowner");

    const types = { "Homeowner": "client", "Provider": "provider", "Enterprise": "enterprise" };

    useEffect(() => {
        fetchAllSubscriptions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    async function fetchAllSubscriptions() {
        Loader.show();

        const [subscriptionsData, error] = await fetch({
            route: "/subscriptions/getAll",
            requestType: "get",
            params: {
                type: types[selectedType],
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllSubscriptions();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        setSubscriptions([...subscriptionsData["res"]]);

        Loader.hide();
    }


    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5 items-center">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Subscriptions</div>
            </div>

            <DropDown className="h-10 shrink-0" label="type" items={Object.keys(types)} active={selectedType} onChange={setSelectedType} />
        </div>

        {(subscriptions == null || subscriptions.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No Subscriptions available
        </div>
            : <div className="flex flex-col gap-2">
                {subscriptions.map((item) =>
                    <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%] h-11">
                        <div className="flex h-full gap-4">
                            <img className="h-full aspect-square object-contain rounded-md" src={`${domain}/static/${item.icon}`} alt={item.name} />
                            <div className="text-black dark:text-slate-300 font-medium text-lg">{`${item.name}`}</div>
                        </div>
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <PrimaryButton onClick={() => navigate(`/edit-subscription/${item.uuid}`)} child={
                                <div className="flex flex-row items-center gap-3 px-2 h-3">
                                    <div className="font-medium">View</div>
                                </div>
                            } />
                            <div className="text-black dark:text-slate-300 font-medium text-md mr-5 max-sm:mr-2">{formatDate(item.createdAt)}</div>
                        </div>
                    </div>)}
            </div>}
    </div>;
}