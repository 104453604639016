import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import showToast from "../../global/functions/toggle-toast";
import fetch from "../../axios/manager";
import PrimaryButton from "../../global/components/button";
import BackIcon from "../../assets/SVGs/back-icon.svg";
import formatDate from "../../global/functions/format-date";
import { domain } from "../../axios/adapter";
import DropDown from "../../global/components/dropdown";

export default function UserDetails() {
    const { uuid } = useParams();
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState(null);
    const [userVerification, setUserVerification] = useState(null);
    const [checkrStatus, setCheckrStatus] = useState(null);
    const [checkrResult, setCheckrResult] = useState(null);

    const stripeIDVItems = { "verified": 1, "processing": 2, "failed": -1 };
    const checkrStatusItems = { "verified": 1, "processing": 2, "rejected": -1 };
    const checkrResultItems = { "passed": "clear", "consider": "consider", "rejected": "rejected" };

    useEffect(() => {
        fetchUserDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userDetails == null || userDetails.profileCompletion == null) return;

        const userVerificationVal = Object.keys(stripeIDVItems).find(key => stripeIDVItems[key] === userDetails.profileCompletion.userVerification);
        setUserVerification(userVerificationVal);

        const checkStatusVal = Object.keys(checkrStatusItems).find(key => checkrStatusItems[key] === userDetails.profileCompletion.checkrVerification);
        setCheckrStatus(checkStatusVal);

        const checkrResultVal = Object.keys(checkrResultItems).find(key => checkrResultItems[key] === userDetails.profileCompletion.checkrStatus);
        setCheckrResult(checkrResultVal);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);


    async function fetchUserDetails() {
        const [userData, error] = await fetch({
            route: `admins/auth/user/${uuid}`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchUserDetails();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        setUserDetails(userData["res"]);
    }

    async function updateUserDetails() {
        let obj = {};

        if (userDetails.user.userType === "provider") {
            obj = {
                checkrVerification: checkrStatusItems[checkrStatus],
                checkrStatus: checkrResultItems[checkrResult],
            };
        }
        else {
            obj = {
                userVerification: stripeIDVItems[userVerification],
            };
        }

        const [, error] = await fetch({
            route: `/admins/auth/user/profileCompletion/${uuid}`,
            requestType: "put",
            body: obj,
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        updateUserDetails();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        showToast("Updated successfully!", true);
    }

    async function onDelete() {
        const [, error] = await fetch({
            route: `/admins/auth/user/${uuid}`,
            requestType: "delete",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onDelete();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        showToast("Deleted successfully!", true);

        navigate(-1);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
            </div>
        </div>
        {userDetails != null && <div className="flex flex-col gap-2">
            <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-4">
                <div className="flex flex-col gap-2">
                    <div className="text-black dark:text-slate-200 font-bold text-lg">User details</div>
                    <div className="rounded-primary p-3 border-secondary dark:border-slate-700 border flex flex-col">
                        <div className="text-black dark:text-slate-300 font-medium text-md">{userDetails.user.email} - {userDetails.user.userType}</div>
                        <div className="text-black dark:text-slate-300 font-medium text-md">Created at - {formatDate(userDetails.user.createdAt)}</div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="text-black dark:text-slate-200 font-bold text-lg">Profile details</div>
                    <div className="rounded-primary p-3 border-secondary dark:border-slate-700 border flex flex-col h-full">
                        {userDetails.profile == null ?
                            <div className="text-black dark:text-slate-300 font-medium text-md">Profile is not created yet</div>
                            : <div className="flex items-center gap-3">
                                <img className="rounded-[50%] aspect-square h-10 object-cover" src={`${domain}/static/${userDetails.profile.profileImage}`} alt="profile" />
                                <div className="flex flex-col">
                                    <div className="text-black dark:text-slate-300 font-medium text-md">{`${userDetails.profile.firstName} ${userDetails.profile.lastName}`}</div>
                                    <div className="text-black dark:text-slate-300 font-medium text-md">Phone no - {userDetails.profile.phoneNumber}</div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="text-black dark:text-slate-200 font-bold text-lg">Current Subscription</div>
                    <div className="rounded-primary p-3 border-secondary dark:border-slate-700 border flex flex-col h-full">
                        {userDetails.profileCompletion?.subscription == null ?
                            <div className="text-black dark:text-slate-300 font-medium text-md">Subscription is not purchased yet</div>
                            : <div className="flex items-center gap-3">
                                <img className="rounded-[50%] aspect-square h-10 object-cover" src={`${domain}/static/${userDetails.profileCompletion.subscription.icon}`} alt="profile" />
                                <div className="flex flex-col flex-grow gap-1">
                                    <div className="flex gap-2 justify-between">
                                        <div className="text-black dark:text-slate-300 font-medium text-md">{userDetails.profileCompletion.subscription.name}</div>
                                        <div className="text-black dark:text-slate-300 font-semibold text-md">${userDetails.profileCompletion.subscription.price}</div>
                                    </div>
                                    <div className="text-black dark:text-slate-300 font-normal text-xs">{userDetails.profileCompletion.subscription.description}</div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <div className="text-black dark:text-slate-200 font-bold text-lg">User verification</div>
            {userDetails.profileCompletion == null ?
                <div className="rounded-primary p-3 border-secondary dark:border-slate-700 border flex flex-col h-full">
                    User has not initiated verification process
                </div>
                : <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 max-sm:grid-flex-col max-sm:grid-cols-1 rounded-primary p-3 border-secondary dark:border-slate-700 border h-full">
                    {userDetails.user.userType === "provider" && <div className="flex flex-col gap-2">
                        <div className="text-black dark:text-slate-200 font-bold text-md">Checkr status</div>
                        <DropDown className="h-10" active={checkrStatus} items={Object.keys(checkrStatusItems)} label="status" onChange={setCheckrStatus} />
                    </div>}
                    {userDetails.user.userType === "provider" && <div className="flex flex-col gap-2">
                        <div className="text-black dark:text-slate-200 font-bold text-md">Checkr result</div>
                        <DropDown className="h-10" active={checkrResult} items={Object.keys(checkrResultItems)} label="status" onChange={setCheckrResult} />
                    </div>}
                    {userDetails.user.userType !== "provider" && <div className="flex flex-col gap-2">
                        <div className="text-black dark:text-slate-200 font-bold text-md">Stripe IDV</div>
                        <DropDown className="h-10" active={userVerification} items={Object.keys(stripeIDVItems)} label="status" onChange={setUserVerification} />
                    </div>}
                </div>}
            {userDetails.profileCompletion != null && <div className="w-max">
                <PrimaryButton onClick={() => updateUserDetails()} child={
                    <div className="font-medium">Submit</div>
                } />
            </div>}

            {userDetails.jobs != null && <div className="flex flex-col gap-3 rounded-primary p-3 border-secondary dark:border-slate-700 border">
                <div className="font-semibold dark:text-slate-200">User jobs</div>
                {userDetails.jobs.length === 0 ?
                    <div className="p-3 dark:text-slate-200">
                        User has no posted jobs
                    </div>
                    : <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 max-sm:grid-flex-col max-sm:grid-cols-1 h-full">
                        {userDetails.jobs.map(job => <div key={job.uuid} className="flex flex-row rounded-primary p-3 gap-4 bg-white dark:bg-slate-800">
                            <img className="aspect-square object-cover rounded-primary w-[20%] h-max" src={`${domain}/static/${job.images[0]}`} alt={job.title} />
                            <div className="flex flex-col gap-1">
                                <div className="text-black dark:text-slate-200 font-bold text-lg">{job.title}</div>
                                <div className="text-black dark:text-slate-200 font-normal text-xs line-clamp-3">{job.fullDescription}</div>
                            </div>
                        </div>)}
                    </div>}
            </div>}

            {userDetails.services != null && <div className="flex flex-col gap-3 rounded-primary p-3 border-secondary dark:border-slate-700 border">
                <div className="font-semibold dark:text-slate-200">User services</div>
                {userDetails.services.length === 0 ?
                    <div className="p-3 dark:text-slate-200">
                        User has no posted services
                    </div>
                    : <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 max-sm:grid-flex-col max-sm:grid-cols-1 h-full">
                        {userDetails.services.map(service => <div key={service.uuid} className="flex flex-row rounded-primary p-3 gap-4 bg-white dark:bg-slate-700">
                            <img className="aspect-square object-cover rounded-primary w-[20%] h-max" src={`${domain}/static/${service.images[0]}`} alt={service.title} />
                            <div className="flex flex-col gap-1">
                                <div className="text-black dark:text-slate-200 font-bold text-lg">{service.title}</div>
                                <div className="text-black dark:text-slate-200 font-normal text-xs line-clamp-3">{service.fullDescription}</div>
                            </div>
                        </div>)}
                    </div>}
            </div>}

            <div className="h-5" />

            {userDetails.user.email !== "deleted" ?
                <button type="button" className={`px-5 w-max rounded-primary gradient-primary bg-red text-white h-11`} onClick={onDelete}>
                    Delete account
                </button>
                : <button type="button" className={`px-5 w-max rounded-primary gradient-primary cursor-not-allowed bg-red text-white h-11`} disabled>
                    Deleted
                </button>}
        </div>}
    </div>;
}