import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import * as Alertdialog from "../../redux/slices/alertdialog";
import * as Loader from "../../global/functions/toggle-loader";

import BackIcon from "../../assets/SVGs/back-icon.svg";
import AddIcon from "../../assets/SVGs/add-icon.svg";
import DeleteButton from "../../global/components/delete-button";
import { useEffect, useState } from "react";
import fetch from "../../axios/manager";
import { useDispatch } from "react-redux";
import PrimarySearchField from "../../global/components/primary-search-field";
import highlightString from "../../global/functions/substring-highlight";

export default function SubListingsManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const { mainListingUUID } = useParams();

    const [subcategories, setSubcategories] = useState(null);
    const [searchSubcategories, setSearchSubcategories] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [offset,] = useState(0);

    useEffect(() => {
        fetchAllSubcategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchAllSubcategories() {
        Loader.show();

        const [couponsData, error] = await fetch({
            route: `/listings/mainlisting/${mainListingUUID}/listings/all`,
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllSubcategories();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        setSubcategories([...couponsData["res"]]);

        Loader.hide();
    }
    async function deleteSubcategory(uuid) {
        Loader.show();

        const [, error] = await fetch({
            route: `/listings/auth/listing/${uuid}`,
            requestType: "delete",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        deleteSubcategory(uuid);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        let index = -1;
        for (let i = 0; i < subcategories.length; i++) {
            if (subcategories[i].uuid === uuid) {
                index = i;
            }
        }

        subcategories.splice(index, 1);

        setSubcategories([...subcategories]);

        Loader.hide();
    }

    async function onSearch(term) {
        Loader.show();

        const [searchListingData, error] = await fetch({
            route: `/listings/mainlisting/${mainListingUUID}/searchraw/${term}`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearch(term);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        console.log(searchListingData);

        setSearchSubcategories(searchListingData["res"]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="add-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Subcategories</div>
            </div>

            <div className="flex gap-2">
                <PrimaryButton onClick={() => navigate(`/edit-category/${mainListingUUID}`)} child={
                    <div className="flex flex-row gap-3">
                        <div className="font-medium">Edit Category</div>
                    </div>
                } />
                <PrimaryButton onClick={() => navigate(`/subcategory-management/${mainListingUUID}/add-subcategory`)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={AddIcon} alt="back-icon" />
                        <div className="font-medium">Add Subcategory</div>
                    </div>
                } />
            </div>
        </div>

        <div className="mb-3 w-full">
            <PrimarySearchField onSearch={onSearch} onTermChange={setSearchTerm} />
        </div>

        {searchTerm.length === 0 ? (subcategories == null || subcategories.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No Subcategories available
        </div>
            : <div className="flex flex-col gap-2">
                {subcategories.map((item) =>
                    <div key={item.uuid} className="bg-white h-11 dark:bg-slate-800 flex flex-row justify-between p-2 rounded-primary items-center w-[100%]">
                        <div className="text-black dark:text-slate-300 font-medium text-lg">{`${item.type}`}</div>

                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <PrimaryButton gradient="blue" onClick={() => navigate(`/subcategory-management/${mainListingUUID}/edit-subcategory/${item.uuid}`)}
                                child={
                                    <div className="flex flex-row items-center gap-3 px-2 h-3">
                                        <div className="font-medium">View</div>
                                    </div>
                                } />
                            <DeleteButton onClick={() => deleteSubcategory(item.uuid)} />
                        </div>
                    </div>)}
            </div>
            : (searchSubcategories == null || searchSubcategories.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                No result
            </div>
                : <div className="flex flex-col gap-2">
                    {searchSubcategories.map((item) =>
                        <div key={item.uuid} className="bg-white h-11 dark:bg-slate-800 flex flex-row justify-between p-2 rounded-primary items-center w-[100%]">
                            {highlightString({ strTarget: item.type, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}

                            <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                                <PrimaryButton gradient="blue" onClick={() => navigate(`/subcategory-management/${mainListingUUID}/edit-subcategory/${item.uuid}`)}
                                    child={
                                        <div className="flex flex-row items-center gap-3 px-2 h-3">
                                            <div className="font-medium">View</div>
                                        </div>
                                    } />
                                <DeleteButton onClick={() => deleteSubcategory(item.uuid)} />
                            </div>
                        </div>)}
                </div>}
    </div>;
}