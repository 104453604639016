import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import TextField from "../../global/components/textfield";
import PrimaryButton from "../../global/components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BackIcon from "../../assets/SVGs/back-icon.svg";

import * as Yup from 'yup';
import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import fetch from "../../axios/manager";
import { useEffect, useState } from "react";
import DeleteButton from "../../global/components/delete-button";

export default function EditSubscription() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const { subscriptionUUID } = useParams();

    const [subscription, setSubscription] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            price: "",
            jobCreate: "",
            connectLimit: "",
            imageUpload: "",
            videoUpload: "",
            chathistory: "",
            description: "",
            points: [],
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            price: Yup.number().required("Required").min(1, "Min limit reached"),
            jobCreate: Yup.number().required("Required").min(0, "Value can't be negative"),
            connectLimit: Yup.number().required("Required").min(0, "Value can't be negative"),
            imageUpload: Yup.number().required("Required").min(0, "Value can't be negative"),
            videoUpload: Yup.number().required("Required").min(0, "Value can't be negative"),
            chatHistory: Yup.number().required("Required"),
            description: Yup.string().required("Required"),
            points: Yup.array().of(
                Yup.string().required("Required")
            )
                .min(1, "Min limit reached"),
        })
    });

    useEffect(() => {
        fetchSubscription();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (subscription == null) return;

        formik.setValues({
            name: subscription.name,
            description: subscription.description,
            price: subscription.price,
            jobCreate: subscription.jobCreate,
            connectLimit: subscription.connectLimit,
            imageUpload: subscription.imageUpload,
            videoUpload: subscription.videoUpload,
            chatHistory: subscription.chatHistory,
            points: subscription.points,
        });


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);


    function onSubmit(values) {
        onEditSubscription();
    }

    async function onEditSubscription() {
        Loader.show();

        const [, error] = await fetch({
            route: `/admins/auth/subscription/${subscriptionUUID}`,
            requestType: "put",
            body: formik.values,
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onEditSubscription();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        navigate(-1);
    }

    async function fetchSubscription() {
        Loader.show();

        const [subscriptionData, error] = await fetch({
            route: `subscriptions/subscription/${subscriptionUUID}`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchSubscription();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        setSubscription(subscriptionData["res"]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex justify-between mb-4">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black font-bold text-lg dark:text-slate-200 my-3">Edit Subscription</div>
            </div>

            <PrimaryButton onClick={() => navigate(`/subscribed-users/${subscriptionUUID}`)} child={
                <div className="flex flex-row gap-3">
                    <div className="font-medium">Subscribed users</div>
                </div>
            } />
        </div>
        <FormikProvider value={formik}>
            <form className="flex flex-col gap-2" onSubmit={formik.handleSubmit}>
                <div className="border-1 rounded-primary p-3 border-secondary dark:border-slate-700">
                    <div className="font-semibold dark:text-slate-200">Subscription Details</div>

                    <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-3 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-4">
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="name-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Name</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="name" id="name-field" placeholder="Name" type="text" size="w-[100%]" />
                                <ErrorMessage name="email" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="price-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Price</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="price" id="price-field" placeholder="Price" type="number" size="w-[100%]" />
                                <ErrorMessage name="price" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="connect-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Connect limit</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="connectLimit" id="connect-field" placeholder="Limit" type="number" size="w-[100%]" />
                                <ErrorMessage name="connectLimit" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="jobcreate-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Job/Service limit</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="jobCreate" id="jobcreate-field" placeholder="Limit" type="number" size="w-[100%]" />
                                <ErrorMessage name="jobCreate" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="imageupload-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Image upload limit</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="imageUpload" id="imageupload-field" placeholder="Limit" type="number" size="w-[100%]" />
                                <ErrorMessage name="imageUpload" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="videoupload-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Video upload limit</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="videoUpload" id="videoupload-field" placeholder="Limit" type="number" size="w-[100%]" />
                                <ErrorMessage name="videoUpload" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="chathistory-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Chat history limit (in days)</label>
                            <div className="flex flex-col gap-1">
                                <TextField required name="chatHistory" id="chathistory-field" placeholder="Limit" type="number" size="w-[100%]" />
                                <ErrorMessage name="chatHistory" className="text-red-500 text-xs font-medium" component="p" />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col w-full gap-2 mb-4">
                        <label htmlFor="description-field" className="dark:text-slate-200 px-2 font-medium w-[100%]">Description</label>
                        <div className="flex flex-col gap-1">
                            <textarea onChange={formik.handleChange} value={formik.values.description} className="border-none outline-none rounded-primary min-h-11 px-3 py-2" required name="description" id="description-field" placeholder="description" size="w-[100%]" />
                            <ErrorMessage name="description" className="text-red-500 text-xs font-medium" component="p" />
                        </div>
                    </div>

                    <label className="dark:text-slate-200 w-2/4 px-2 font-medium mb-3 max-sm:w-[100%]">Points</label>
                    <FieldArray name="points">
                        {({ push, remove }) => (
                            <div className="flex flex-col gap-3">
                                {formik.values.points.map((item, index) => {
                                    return <div className="flex flex-col gap-1">
                                        <div className="flex flex-row items-center dark:bg-slate-800 dark:text-slate-200 rounded-primary bg-white pe-1">
                                            <TextField key={index} value={item} required name={`points[${index}]`} type="text" size="w-[100%]" />
                                            <DeleteButton onClick={() => remove(index)} />
                                        </div>
                                        <ErrorMessage name={`points[${index}]`} className="text-red-500 text-xs font-medium" component="p" />
                                    </div>;
                                })}
                                <button type="button" onClick={() => push("")} className="dark:bg-slate-800 dark:text-slate-200 text-black bg-white flex justify-center items-center rounded-primary h-11 font-medium">
                                    + Add more
                                </button>
                            </div>
                        )}
                    </FieldArray>

                    <div className="flex justify-start mt-3">
                        <PrimaryButton onClick={formik.handleSubmit} child={
                            <div className="flex flex-row gap-3">
                                <div className="font-medium">Submit</div>
                            </div>
                        } />
                    </div>
                </div>
            </form>
        </FormikProvider>
    </div>;
}