import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../assets/SVGs/back-icon.svg";
import AddIcon from "../../assets/SVGs/add-icon.svg";

import * as Loader from "../../global/functions/toggle-loader";
import * as Alertdialog from "../../redux/slices/alertdialog";
import PrimaryButton from "../../global/components/button";
import fetch from "../../axios/manager";
import DeleteButton from "../../global/components/delete-button";
import PrimarySearchField from "../../global/components/primary-search-field";
import highlightString from "../../global/functions/substring-highlight";
import LoadMore from "../../global/components/load-more";

export default function AdminManagementDashboard() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const [admins, setAdmins] = useState(null);
    const [adminHasMore, setAdminHasMore] = useState(true);
    const [searchAdminHasMore, setSearchAdminHasMore] = useState(true);
    const [searchAdmins, setSearchAdmins] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);

    useEffect(() => {
        fetchAllAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        setSearchOffset(0);
        setSearchAdminHasMore(true);
        setSearchAdmins([]);

    }, [searchTerm]);

    useEffect(() => {
        if (offset === 0 || !adminHasMore) return;

        fetchAllAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        if (searchOffset === 0 || !searchAdminHasMore) return;

        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOffset]);


    async function fetchAllAdmins() {
        Loader.show();

        const [adminsData, error] = await fetch({
            route: "admins/auth/all",
            requestType: "get",
            params: {
                limit: 10,
                offset: offset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchAllAdmins();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        const madminData = adminsData["res"];

        if (madminData.length < 10) {

            setAdminHasMore(false);
        }

        setAdmins([
            ...(admins ?? []),
            ...madminData
        ]);

        Loader.hide();
    }

    async function deleteAdmin(uuid) {
        Loader.show();

        const [, error] = await fetch({
            route: `admins/auth/admin/${uuid}`,
            requestType: "delete",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        deleteAdmin();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        let index = -1;
        for (let i = 0; i < admins.length; i++) {
            if (admins[i].uuid === uuid) {
                index = i;
            }
        }

        admins.splice(index, 1);

        setAdmins([...admins]);

        Loader.hide();
    }

    async function onSearch(term) {
        Loader.show();

        const [searchAdminData, error] = await fetch({
            route: `/admins/auth/search/${term}`,
            requestType: "get",
            params: {
                limit: 10,
                offset: searchOffset,
                sort: "DESC",
            }
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        onSearch(term);
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        const mSearchAdminData = searchAdminData["res"];

        if (mSearchAdminData.length < 10) {
            setSearchAdminHasMore(false);
        }

        setSearchAdmins([
            ...(searchAdmins ?? []),
            ...mSearchAdminData,
        ]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
                <div className="text-black dark:text-slate-200 font-bold text-lg">All Admins</div>
            </div>
            <PrimaryButton onClick={() => navigate("/add-admin")} child={
                <div className="flex flex-row gap-3">
                    <img className="primary-icon" src={AddIcon} alt="add-icon" />
                    <div className="font-medium">Add Admin</div>
                </div>
            } />
        </div>

        <div className="mb-3 w-full">
            <PrimarySearchField onSearch={onSearch} onTermChange={setSearchTerm} />
        </div>

        {searchTerm.length === 0 ? (admins == null || admins.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
            No Admins available
        </div>
            : <div className="flex flex-col gap-2">
                {admins.map((item) =>
                    <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                        <div className="text-black dark:text-slate-300 font-medium text-lg">{item.email}</div>
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <PrimaryButton gradient="blue" onClick={() => navigate(`/edit-admin/${item.uuid}`)} child={
                                <div className="flex flex-row items-center gap-3 px-2 h-3">
                                    <div className="font-medium">View</div>
                                </div>
                            } />
                            <DeleteButton onClick={() => deleteAdmin(item.uuid)} />
                        </div>
                    </div>)}
                {adminHasMore && <LoadMore onClicked={() => setOffset(offset + 10)} />}
            </div>
            : (searchAdmins == null || searchAdmins.length === 0) ? <div className="flex-grow min-h-[50dvh] flex items-center justify-center font-semibold text-lg dark:text-slate-200">
                No result
            </div> : <div className="flex flex-col gap-2">
                {searchAdmins.map((item) =>
                    <div key={item.uuid} className="bg-white dark:bg-slate-800 flex flex-row justify-between pl-5 pr-2 py-2 rounded-primary items-center w-[100%]">
                        {highlightString({ strTarget: item.email, subStr: searchTerm, styles: "text-black dark:text-slate-300 font-medium text-lg inline-block" })}
                        <div className="flex flex-row items-center gap-2 max-sm:gap-1 max-sm:w-[80%] text-end justify-end">
                            <PrimaryButton gradient="blue" onClick={() => navigate(`/edit-admin/${item.uuid}`)} child={
                                <div className="flex flex-row items-center gap-3 px-2 h-3">
                                    <div className="font-medium">View</div>
                                </div>
                            } />
                            <DeleteButton onClick={() => deleteAdmin(item.uuid)} />
                        </div>
                    </div>)}
                {searchAdminHasMore && <LoadMore onClicked={() => setSearchOffset(searchOffset + 10)} />}
            </div>}
    </div>;
}