import React from "react";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";

export default function ToploadingBar() {
    const topLoadingBarSlice = useSelector(state => state.topLoadingBarSlice);

    return <LoadingBar color={document.documentElement.classList.contains("dark") ? "#109CF1" : "#0A35BD"}
        height={4}
        progress={topLoadingBarSlice.progress} />;
};