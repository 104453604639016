import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../global/components/button";
import BackIcon from "../../assets/SVGs/back-icon.svg";
import * as Alertdialog from "../../redux/slices/alertdialog";
import * as Loader from "../../global/functions/toggle-loader";
import formatDate from "../../global/functions/format-date";
import fetch from "../../axios/manager";

export default function ViewFeedbackScreen() {
    const { feedbackUUID } = useParams();

    const [feedback, setFeedback] = useState(null);

    const dispatcher = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        fetchFeedbackDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchFeedbackDetails() {
        const [userData, error] = await fetch({
            route: `admins/auth/feedback/${feedbackUUID}`,
            requestType: "get",
        });

        if (error != null) {
            dispatcher(Alertdialog.show({
                type: "error",
                title: "Error",
                description: error,
                positiveButtonText: "Retry",
                onButtonClicked: (value) => {
                    if (value) {
                        fetchFeedbackDetails();
                    }
                }
            }));

            Loader.hide();
            return;
        }

        Loader.hide();

        setFeedback(userData["res"]);
    }

    return <div className="flex flex-col w-[100%]">
        <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-row items-center gap-4">
                <PrimaryButton onClick={() => navigate(-1)} child={
                    <div className="flex flex-row gap-3">
                        <img className="primary-icon" src={BackIcon} alt="back-icon" />
                        <div className="font-medium">Back</div>
                    </div>
                } />
            </div>
        </div>
        {feedback != null && <div className="flex flex-col gap-2">
            <div className="w-[100%] grid grid-flow-row grid-cols-2 gap-4 mt-2 max-sm:grid-flex-col max-sm:grid-cols-1 mb-4">
                <div className="flex flex-col gap-2">
                    <div className="text-black dark:text-slate-200 font-bold text-lg">User details</div>
                    <div className="rounded-primary p-3 border-secondary dark:border-slate-700 border flex flex-col">
                        <div className="text-black dark:text-slate-300 font-medium text-md">{feedback.email} - {feedback.userType}</div>
                        <div className="text-black dark:text-slate-300 font-medium text-md">Created at - {formatDate(feedback.createdAt)}</div>
                    </div>
                </div>
            </div>
            <div className="text-black dark:text-slate-300 font-medium text-md">{feedback.feedback}</div>
        </div>}
    </div>;
}