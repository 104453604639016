import DarkModeButton from "../components/toggle-theme-button";
import LogOutButton from "../components/logout-button";
import Logo from "../../assets/SVGs/logo.svg";
import { Link } from "react-router-dom";

const TopBar = () => {
    return <div className='w-100 bg-white dark:bg-slate-800 flex justify-between p-3 max-[400px]:px-0'>

        <Link to="/">
            <img className="h-7" src={Logo} alt="logo" />
        </Link>

        <div className='flex row gap-5'>
            <DarkModeButton />

            <LogOutButton />
        </div>
    </div>;
};

export default TopBar;